import React from "react";
import Device from "../images/device.jpg";
import Apps from "../images/apps.jpg";
import Teamlap from "../images/lapteam.jpg";
import GroupImage from "../images/team.jpg";

export default function Gallery() {
  return (
    <div className="gallerywrapper">
      <div className="galleryinner">
        <div className="gallerytitle">
          <h1>Gallery</h1>
          <p>About Us</p>
        </div>
        <div className="gallery">
          <img className="firstimage" src={GroupImage} alt="firstimage" />
          <img className="secondimage" src={Teamlap} alt="firstimage" />
          <div className="littleimages">
            <img className="thirdimage" src={Device} alt="firstimage" />
            <img className="fourthimage" src={Apps} alt="firstimage" />
          </div>
        </div>
      </div>
    </div>
  );
}
