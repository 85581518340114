import React from "react";
import Header from "../components/Header";
import Headerimage from "../images/headerimage.png";
import GroupImage from "../images/team.jpg";
import Imagination from "../images/imagination.jpg";
import PhotoAndTextBlock from "../components/PhotoAndTextBlock";
//Icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloudDoneIcon from "@mui/icons-material/BookmarkAdded";
import SimCardDownloadIcon from "@mui/icons-material/DesignServices";
import CodeIcon from "@mui/icons-material/Code";
import AddchartIcon from "@mui/icons-material/Language";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div className="homewrapper">
      <Header
        Title="Custom Software development"
        Subtitle="Web and mobile"
        ImageSize={"1300px"}
        Image={Headerimage}
        Linkref="#homesection"
      />
      <div className="homemainsection">
        <div className="homebanner">
          <h1>Full service agency</h1>
          <p>
            Serving global leaders to entrepreneurs, we tailor our process based
            on your scale and structure.
          </p>
          <div className="bannericons">
            <span>
              <CodeIcon
                style={{ color: "white", fontSize: "60px", margin: "0 20px" }}
              />
              <p> App development </p>
            </span>
            <span>
              <AddchartIcon
                style={{ color: "white", fontSize: "60px", margin: "0 20px" }}
              />
              <p> Web development </p>
            </span>
            <span>
              <SimCardDownloadIcon
                style={{ color: "white", fontSize: "60px", margin: "0 20px" }}
              />
              <p> Design & structure </p>
            </span>
            <span>
              <AccountTreeIcon
                style={{ color: "white", fontSize: "60px", margin: "0 20px" }}
              />
              <p> Best Strategy </p>
            </span>
            <span>
              <CloudDoneIcon
                style={{ color: "white", fontSize: "60px", margin: "0 20px" }}
              />
              <p> User experience </p>
            </span>
          </div>
        </div>
        <div id="homesection" className="mainsectionone">
          <PhotoAndTextBlock
            Title={"Experience"}
            Span={"Our solution"}
            Linkref="/About"
            MainText={
              "We have over a decade of experience in creating custom software and mobile solutions to help you accomplish your goals. From the first design to the launch of your Application. We stand by."
            }
            Image={GroupImage}
            Button
          />
          <PhotoAndTextBlock
            Title={"The Vision"}
            Span={"Our solution"}
            MainText={
              "We don't care how crazy your idea might be. As long as you have the vision, we have the solution. We let your imagination become real with our solutions and strategies. "
            }
            Image={Imagination}
            Reversed
            Linkref="/About"
            Button
          />
        </div>
      </div>
      <div className="homemainsectiontwo">
        <Gallery />
        {/* <Collapse
          Image={Phoneimages}
          Collapse1Title="Hand us your toughest challenges"
          Collapse1Text="Looking for a custom software or mobile solution? With over a decade of software development experience, we will work with you to create solutions that can help you achieve your goals."
          Collapse2Title="Get solutions that let you scale"
          Collapse2Text="Lorem ipsum dolor sit amet, convenire conclusionemque eu vim, mea simul ullamcorper id. Ipsum tamquam te vix. Et unum quidam duo. Democritum dissentiet ne qui, elitr dignissim percipitur quo te."
          Collapse3Title="A seamless experience"
          Collapse3Text="Quo cu accumsan oporteat neglegentur, reque aeterno consetetur his an. An sanctus sententiae interesset mel, at vis affert iracundia eloquentiam. Dico molestie lucilius at vel. Duo ut quas liber insolens."
          Collapse4Title="Flexibility to Innovate"
          Collapse4Text="Melius dissentiet nam ne, vel ei graeco elaboraret, has ei suas oblique pertinacia. Nam cu legendos petentium instructior, voluptua indoctum cum at, dicam tractatos sea te. Eum ne iusto dolores dissentiunt."
        /> */}
      </div>
      <Footer />
    </div>
  );
}
