import React from "react";
import Footer from "../components/Footer";
import AboutHeaderImage from "../images/aboutHeader.png";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";

export default function Contact() {
  return (
    <div className="homewrapper">
      <Header
        Title="Get in touch with us"
        Subtitle="Feel free to contact us."
        Linkref="#contactsection"
        ImageSize={"900px"}
        Image={AboutHeaderImage}
      />
      <div className="homemainsection">
        <div className="mainsectionone">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}
