import React from "react";
import { Link } from "react-router-dom";

export default function PhotoAndTextBlock({
  Image,
  Reversed,
  Title,
  Span,
  MainText,
  Linkref,
  Button,
}) {
  return (
    <div className={Reversed ? "photoandtext reversed" : "photoandtext"}>
      <div className="photoandtexttextdiv">
        <h1> {Title} </h1>
        <span> {Span} </span>
        <p>{MainText}</p>
        {Button ? <Link to={`${Linkref}`}>Read more</Link> : ""}
      </div>
      <img src={Image} alt="GroupImage" />
    </div>
  );
}
