import React from "react";
import App from "../images/app.jpg";
// import Cms from "../images/cms.png";

export default function ShopLine() {
  return (
    <div className="pictureline">
      <div className="picturelineinner">
        <h2> Service Overview </h2>
        <span> Experienced and skilled </span>
        <div className="picturelineinnerwrapper">
          <div className="picturebox service">
            <img src={App} alt="aboutpicone" />
            <div className="picturetext">
              <h2> App Development </h2>
              <span>BY ARRANGEMENT</span>
            </div>
          </div>
          <div id="onepager" className="picturebox service">
            <img src={App} alt="aboutpicone" />
            <div className="picturetext">
              <h2>One Pager</h2>
              {/* <span>750,00€+</span> */}
              <span>BY ARRANGEMENT</span>
            </div>
          </div>
          <div className="picturebox service">
            <img src={App} alt="aboutpicone" />
            <div className="picturetext">
              <h2>Static Website</h2>
              {/* <span> 1.200,00€+ </span> */}
              <span>BY ARRANGEMENT</span>
            </div>
          </div>
          <div className="picturebox service">
            <img src={App} alt="aboutpicone" />
            <div className="picturetext">
              <h2>CMS System</h2>
              {/* <span>3.800,00€+</span> */}
              <span>BY ARRANGEMENT</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
