import React from "react";

export default function ContactForm() {
  return (
    <div className="contactwrapper">
      <div id="contactsection" className="textblock1">
        <h1>Germany Bav.</h1>
        <h3> Our main office </h3>
        <p>
          Am Söldnermoos 6, <br /> 85399 Hallbergmoos, Germany <br /> Email:
          info.exude@gmail.com Phone: +49 1525 9348250
        </p>
      </div>
      <div className="textblock1">
        <h1>Austin TX.</h1>
        <h3> coming soon </h3>
        <p style={{ opacity: "50%" }}>
          Am Söldnermoos 6, <br /> 85399 Hallbergmoos, Germany <br /> Email:
          info.exude@gmail.com Phone: +49 1525 9348250
        </p>
      </div>
      {/* <form>
        <input placeholder="First Name*" />
        <input placeholder="Last Name*" />
        <input placeholder="Email*" />
        <input placeholder="Website" />
        <textarea className="message" placeholder="Message" />
      </form> */}
    </div>
  );
}
