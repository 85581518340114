import React from "react";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";

export default function Header({ Image, ImageSize, Title, Subtitle, Linkref }) {
  const ref = Linkref?.includes("#");
  return (
    <div className="homeheader">
      <Navigation />
      <div className="homeheadermain">
        <div className="homeheadermaintext">
          <h1> {Title || "Custom Software development"} </h1>
          <h3> {Subtitle || "Web and mobile"} </h3>
          {ref ? (
            <a href={`${Linkref}`}>Find out more</a>
          ) : (
            <Link to={`${Linkref || "About"}`}>Find out more</Link>
          )}
        </div>
        <img style={{ width: ImageSize }} src={Image} alt="Headerimage" />
      </div>
    </div>
  );
}
