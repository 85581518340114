import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import { useEffect } from "react";
import Contact from "./pages/Contact";
// import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

function App() {
  // const [scrolled, setScrolled] = useState(false);

  // const switchButton = () => {
  //   if (window.scrollY >= 80) {
  //     setScrolled(true);
  //   } else {
  //     setScrolled(false);
  //   }
  // };

  function ScrollFixer() {
    const location = useLocation();
    useEffect(() => {
      document.scrollingElement.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
  }

  return (
    <Router>
      <ScrollFixer></ScrollFixer>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/About">
          <About />
        </Route>
        <Route path="/Contact">
          <Contact />
        </Route>
      </Switch>
      {/* <span
        onClick={() => document.scrollingElement.scrollTo(0, 0)}
        className="scroller"
        style={scrolled ? { opacity: "1" } : { opacity: "0" }}
      >
        <KeyboardArrowUpRoundedIcon style={{ fontSize: "30px" }} />
      </span> */}
    </Router>
  );
}

export default App;
