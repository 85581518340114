import React from "react";
import { Link } from "react-router-dom";
import Apple from "../images/apple.svg";
import Google from "../images/google.png";
//Images
// import Client1 from "../images/client1.png";
// import Client2 from "../images/client2.png";
// import Client3 from "../images/client3.png";
// import Client4 from "../images/client4.png";
// import Client5 from "../images/client5.png";
// import Client6 from "../images/client6.png";
//Icons
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footerbanner">
        <h2> FEATURED CLIENTS </h2>
        <div className="footerbannerimages">
          <a target="_blank" href="https://www.sb-konzept.de/">
            SBK
          </a>
          <a target="_blank" href="https://www.supacar.de/">
            SPC
          </a>
          <a target="_blank" href="https://rms-intelligents.com/">
            RMS
          </a>
          <a target="_blank" href="https://www.bb-mobility.com/">
            BBM
          </a>
          <a target="_blank" href="https://www.sb-konzept.de/">
            A4U
          </a>
        </div>
      </div>
      <div className="footertext">
        <div className="footertextblock">
          <h2>About Company</h2>
          <Link to="/About"> About us </Link>
          <Link to="/About"> Our team </Link>
          <Link to="/Apps"> Projects </Link>
          <Link to="/" style={{ opacity: ".3" }}>
            Terms & Conditions
          </Link>
        </div>
        <div className="footertextblock">
          <h2>Customer service</h2>
          <Link to="/">Our Services</Link>
          <Link to="/Contact"> Get started </Link>
          <Link to="/" style={{ opacity: ".3" }}>
            Privacy policy
          </Link>
          <Link to="/" style={{ opacity: ".3" }}>
            Cookies policy
          </Link>
        </div>
        <div className="footertextblock">
          <h2>Product and support</h2>
          <Link to="/About"> About the app </Link>
          <Link to="/About"> Development </Link>
          <Link to="/Contact"> Online Help </Link>
          <Link to="/About"> FAQs </Link>
        </div>
        <div className="footertextblock">
          <h2>Contact us</h2>
          <Link to="/Contact"> Am Söldnermoos 6, </Link>
          <Link to="/Contact"> 85399 Hallbergmoos, Germany</Link>
          <Link to="/Contact"> Email: info.exude@gmail.com</Link>
          <Link to="/Contact"> Phone: +49 1525 9348250 </Link>
          {/* <Link to="/Contact"> © Copyright The Exude </Link> */}
        </div>
      </div>
      <div className="socials">
        <div className="networks">
          <h2>Social networks</h2>
          <div className="networklinksdiv" style={{ opacity: ".3" }}>
            <span className="netlink">
              <GoogleIcon style={{ fontSize: "25px" }} />
            </span>
            <span className="netlink">
              <TwitterIcon style={{ fontSize: "25px" }} />
            </span>
            <span className="netlink">
              <YouTubeIcon style={{ fontSize: "25px" }} />
            </span>
            <span className="netlink">
              <InstagramIcon style={{ fontSize: "25px" }} />
            </span>
          </div>
        </div>
        <div className="stores" style={{ opacity: ".3" }}>
          <Link to="/">
            <img src={Apple} alt="apple" />
          </Link>
          <Link to="/">
            <img src={Google} alt="google" />
          </Link>
        </div>
      </div>
    </div>
  );
}
