import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PhotoAndTextBlock from "../components/PhotoAndTextBlock";
import AboutHeaderImage from "../images/aboutHeader.png";
import Device from "../images/device.jpg";
import People from "../images/people.jpg";
import ShopLine from "../components/ShopLine";
import GroupImage from "../images/team.jpg";
import Imagination from "../images/imagination.jpg";

export default function About() {
  return (
    <div className="homewrapper">
      <Header
        Title="Who are We and what do we offer?"
        Subtitle="About Us"
        ImageSize={"900px"}
        Linkref="#aboutsection"
        Image={AboutHeaderImage}
      />
      <div className="homemainsection">
        <div className="mainsectionone" id="aboutsection">
          <PhotoAndTextBlock
            Image={Device}
            Title={"No Limits"}
            Span={"About Us"}
            MainText={
              "Dreams are like creativity - they have no limits. We develop marketing solutions based on which there are no limits to your business. We position your brand razor-sharp in the market and skilfully get to the heart of the rational and emotional brand added value."
            }
          />
          <PhotoAndTextBlock
            Image={People}
            Title={"Build Value"}
            Span={"About Us"}
            MainText={
              "People give brands power, suggestion and strength. But also empathy and sensitivity. We put everything on to one big plate. Exude gives your brand a recognizable voice, a unique design and identity."
            }
            Reversed
          />
        </div>
      </div>
      <ShopLine />

      <div className="homemainsection">
        <div className="mainsectionone">
          <PhotoAndTextBlock
            Title={"Experience"}
            Span={"About Us"}
            Linkref="/About"
            MainText={
              "We have over a decade of experience in creating custom software and mobile solutions to help you accomplish your goals. From the first design to the launch of your Application. We stand by."
            }
            Image={GroupImage}
          />
          <PhotoAndTextBlock
            Title={"The Vision"}
            Span={"About Us"}
            MainText={
              "We don't care how crazy your idea might be. As long as you have the vision, we have the solution. We let your imagination become real with our solutions and strategies. "
            }
            Image={Imagination}
            Reversed
            Linkref="/About"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
