import React, { useState } from "react";
import { Link } from "react-router-dom";
import Exude from "../images/exude-black.png";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Navigation() {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className="navigationwrapper">
      <Link to="/">
        <img src={Exude} alt="Logo" />
      </Link>
      <div className="navigationlinks">
        <Link to="/">Home</Link>
        <Link to="/About">About Us</Link>
        <Link className="servicehover" to="/">
          Apps
          <KeyboardArrowDownRoundedIcon
            className="navigationicon"
            style={{ color: "#00aaff", marginLeft: "5px", fontSize: "25px" }}
          />
          <div className="dropdown">
            <div className="dropdownsingle">
              <a href="https://hopon-website.vercel.app/">Hopon</a>
            </div>
            <div className="dropdownsingle">
              <a href="https://apps.apple.com/de/app/xallet-haushaltsbuch/id1553671717">
                Xallet
              </a>
            </div>
          </div>
        </Link>
        <Link to="/Contact">Contact Us</Link>
      </div>
      <div className="burger" onClick={() => setMenu(true)}>
        <MenuIcon style={{ fontSize: "35px" }} />
      </div>
      <Drawer open={menu} onClose={() => setMenu(false)} anchor="top">
        <div className="mobilemenu">
          <span onClick={() => setMenu(false)}>
            <ClearIcon className="closemenu" />
          </span>
          <Link to="/">Home</Link>
          <Link to="/About">About Us</Link>
          <span className="mobiledropper">
            <span onClick={() => history.push("/")}>Apps</span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setDropdown((prevState) => !prevState)}
            >
              <KeyboardArrowDownRoundedIcon
                className="navigationicon"
                style={
                  dropdown
                    ? {
                        transform: "rotate(180deg)",
                        color: "#00aaff",
                        marginLeft: "5px",
                        fontSize: "25px",
                        transition: "all .5s",
                      }
                    : {
                        color: "#00aaff",
                        marginLeft: "5px",
                        fontSize: "25px",
                        transition: "all .5s",
                      }
                }
              />
            </span>
          </span>
          <div
            className="mobiledropdonwn"
            style={
              dropdown
                ? { heigth: "auto", width: "auto", transform: "scale(1)" }
                : {
                    height: "0",
                    width: "0",
                    opacity: "0",
                    transform: "scale(0)",
                  }
            }
          >
            <a href="https://hopon-website.vercel.app/" target="_blank">
              Hopon
            </a>
            <a
              href="https://apps.apple.com/de/app/xallet-haushaltsbuch/id1553671717"
              target="_blank"
            >
              Xallet
            </a>
          </div>
          <Link to="/Contact">Contact Us</Link>
        </div>
      </Drawer>
    </div>
  );
}
